import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Test from '../Test';
import Navbar from './Navbar';
import Footer from './Footer'
import { auth, signOut, onAuthStateChanged } from '../../firebaseConfig';
import Header from './Header';
import { Routes, Route } from 'react-router-dom';
import NotFound from './content/NotFound';
import Usuarios from './content/Usuarios';
import Google from './content/google/Google';
import CreateElementG from './content/google/CreateElementG';
import NewUser from './content/users/NewUser';

const Dashboard = ({ user, setuser }) => {

  const [title, settitle] = useState('Dashboard')

  const navigate = useNavigate();

  const signOutGoogle = () => {
    signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        console.log('Usuario Activo :D', user)
        setuser(user)
        // ...
      } else {
        // User is signed out
        // ...
        console.log('Logout', user)
        navigate('/login')
        setuser(null);
      }
    });
  }, [user])

  return (
    <>
      {
        user ? (
          <div id='wrapper'>
            <nav className='navbar navbar-dark align-items-start sidebar sidebar-dark accordion bg-gradient-primary p-0 d-none d-md-block'>
              <Navbar />
            </nav>
            <div id='content-wrapper' className='d-flex flex-column'>
              <div id='content'>
                <Header user={user} title={title} signOutGoogle={signOutGoogle} />
                <div className='container-fluid'>
                  <Routes>
                    <Route path='*' element={<NotFound settitle={settitle} />} />
                    <Route path='/'element={<Google settitle={settitle} user={user} />} />
                    <Route path='/usuarios' element={<Usuarios settitle={settitle} />} />
                    <Route path='/usuarios/user' element={<NewUser settitle={settitle} />} />
                    <Route path='/google' element={<Google settitle={settitle} user={user} />} />
                    <Route path='/google/create' element={<CreateElementG settitle={settitle} user={user} />} />
                  </Routes>
                </div>
              </div>
              <Footer />
            </div>
          </div >
        ) : null
      }
    </>
  )
}

export default Dashboard