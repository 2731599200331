import React, { useState, useEffect } from 'react'

const TableBars = ({ row }) => {
  const [percentBudget, setpercentBudget] = useState(0);
  const [percentTime, setpercentTime] = useState(0);

  useEffect(() => {

    const endDate = row.endDateMillis - row.startDateMillis;
    const today = new Date().getTime() - row.startDateMillis;

    const days = ((endDate) / (1000 * 60 * 60 * 24));

    setTimeout(() => {
      setpercentBudget(numberWithCommas(percent(row.cost, row.budget)))
      setpercentTime(numberWithCommas(percent(today, endDate)))

    }, 800);

  }, [])

  const percent = (a, b) => {
    return (a / b) * 100;
  }

  const numberWithCommas = (x) => {
    x = parseFloat(x).toFixed(2);
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className='td-table tooltiptardis py-1'>
      <span className='tooltiptext'>Diferencia:&nbsp;{numberWithCommas(percentBudget - percentTime)}</span>
      <span className="progress mb-2">
        <span
          className="progress-bar bg-blue"
          aria-valuenow={`${percentTime}`}
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ width: `${percentTime}%` }}
        >
          <i className="fa-regular fa-clock"><span className='fa-solid mx-1'> {percentTime}%</span>
          </i>
        </span>
      </span>
      <span className="progress">
        <span
          className="progress-bar bg-success"
          aria-valuenow={`${percentBudget}`}
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ width: `${percentBudget}%` }}
        >
          <i className="fa-solid fa-dollar-sign"><span className='fa-solid mx-1'> {percentBudget}%</span>
          </i>
        </span>
      </span>
    </div>
  )
}

export default TableBars