import React from 'react'
import { Link } from "react-router-dom";


const Navbar = () => {

  return (
    <>
      
        <div className="container-fluid d-flex flex-column p-0"><Link className="navbar-brand d-flex justify-content-center align-items-center sidebar-brand m-0" to='/'>
          <div className="sidebar-brand-icon"><img height='40rem;' src="resources\icon\tardis.svg" alt="" /></div>
          <div className="sidebar-brand-text mx-3"><span>TARDIS</span></div>
        </Link>
          <hr className="sidebar-divider my-0" />
          <ul className="navbar-nav text-light" id="accordionSidebar">
            <li className="nav-item"><Link to='/' className="nav-link"><i className="fas fa-tachometer-alt"></i><span>Dashboard</span></Link></li>
            <li className="nav-item"><Link to='/usuarios' className="nav-link"><i className="fas fa-user"></i><span>Usuarios</span></Link></li>
            <li className="nav-item"><Link to='/facebook' className="nav-link"><i className="fa-brands fa-facebook"></i><span>Facebook</span></Link></li>
            <li className="nav-item"><Link to='/google' className="nav-link"><i className="fa-brands fa-google"></i><span>Google</span></Link></li>
            {/* <li className="nav-item"><Link to='' className="nav-link" href="login.html"><i className="far fa-user-circle"></i><span>Login</span></Link></li>
            <li className="nav-item"><Link to='' className="nav-link" href="register.html"><i className="fas fa-user-circle"></i><span>Register</span></Link></li> */}
          </ul>
        </div>
      
    </>
  )
}

export default Navbar