import React, { useEffect, useState } from 'react'
import OverviewElement from './OverviewElement';
import { useNavigate } from 'react-router-dom';
import { collection, db, query, where, getDocs, doc, onSnapshot } from '../../../../firebaseConfig';
import Table from './Table';

const OverviewG = ({ user }) => {

  const [accounts, setaccounts] = useState([]);
  const [searchTerm, setsearchTerm] = useState("");
  const [searchResults, setsearchResults] = useState([]);
  const [accountsTable, setaccountsTable] = useState([]);
  const [pending, setpending] = useState(true);

  const navigate = useNavigate();

  const getAccounts = async (user) => {
    const result = [];
    console.log("uid: ", user.uid)

    const q = query(collection(db, "tardis/entities/g_account"), where("createdBy", "==", user.uid));
    //const q = query(collection(db, "tardis/entities/g_account"));

    const querySnapshot = await getDocs(q);
    querySnapshot.docs.map((doc) => {
      // doc.data() is never undefined for query doc snapshots
      //console.log(doc.id, " => ", doc.data());
      
      return result.push({
        ...doc.data(),
        startDateMillis: doc.data().startDate.toMillis(),
        endDateMillis: doc.data().endDate.toMillis()
      })
    });
    console.log('result: ', result)
    setaccounts(result);
    setaccountsTable(accounts);
    setpending(false);
    return result;
  }

  useEffect(() => {
    const q = query(collection(db, "tardis/entities/g_account"), where("createdBy", "==", user.uid));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      console.log("Actualizando...")
      setaccounts([]);
      getAccounts(user)
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          console.log("New record: ")
        }
        if (change.type === "modified") {
          console.log("Modified record: ")
        }
        if (change.type === "removed") {
          console.log("Removed record: ")
        }
      });
    });
    return () => unsubscribe()
  }, [])

  useEffect(() => {
    if (searchTerm.length > 2) {
      const results = accounts.filter(account => {
        const nameAccount = account.nameAccount.toLowerCase();

        if (nameAccount.includes(searchTerm.toLocaleLowerCase())) {
          return account
        }
      })
      setsearchResults(results);
      setaccountsTable(results);
    } else {
      setaccountsTable(accounts);
      setsearchResults([]);
    }
  }, [searchTerm, accounts])


  const onChange = (e) => {

    if (e.target.name === 'searchTerm') {
      setsearchTerm(e.target.value);
    }
  }

  const searchAccount = (text, searchTerm) => {
    if (text.contains(searchTerm)) {
      console.log("texto: " + text + " buscando: " + searchTerm)
      return true
    } else {
      return false
    }
  }

  return (
    <div className="container-fluid">
      <h3 className="text-dark mb-4">Overview</h3>
      <div className="card shadow">
        <div className="card-header py-3">
          <p className="text-primary m-0 fw-bold">Cuentas </p>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6 text-nowrap">
              <button className='btn' type='button' onClick={() => (navigate('/google/create'))} ><h5 className=''>Agregar cuenta <i className="fa-solid fa-circle-plus mx-1 icon-add"></i></h5></button>
              {/* <div id="dataTable_length" className="dataTables_length" aria-controls="dataTable"><label className="form-label">Show <select className="d-inline-block form-select form-select-sm">
                <option value="10" selected>10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select> </label></div> */}
            </div>
            <div className="col-md-6">
              <div className="text-md-end dataTables_filter" id="dataTable_filter"><label className="form-label">
                <input
                  type="search"
                  className="form-control form-control-sm"
                  id='searchTerm'
                  name='searchTerm'
                  aria-controls="dataTable"
                  placeholder="Buscar"
                  onChange={onChange}
                  value={searchTerm}
                />
              </label></div>
            </div>
          </div>
          <div className="table-responsive table" id="dataTable" role="grid" aria-describedby="dataTable_info">            
            <Table data={accountsTable} pending={pending} />
          </div>
          <div className="row">
            <div className="col-md-6 align-self-center">
              <p id="dataTable_info" className="dataTables_info" role="status" aria-live="polite">Mostrando {accountsTable.length} cuentas</p>
            </div>
            <div className="col-md-6">
            </div>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default OverviewG