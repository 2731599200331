import React from 'react'

const Footer = () => {
  return (
    <footer className="bg-white sticky-footer">
      <div className="container my-auto">
        <div className="text-center my-auto copyright"><span>Made by Koneko </span><i className="fa-solid fa-gamepad"></i></div>
      </div>
    </footer>
  )
}

export default Footer