import { db, doc, setDoc } from '../../../../firebaseConfig';
import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';

const NewUser = () => {

  const navigate = useNavigate();

  const [name, setname] = useState('');
  const [email, setemail] = useState('');
  const [role, setrole] = useState('Cliente');
  const [phone, setphone] = useState('');

  const createUser = (e) => {
    e.preventDefault();
    console.log('Agregar seguimiento :D')
    if (name !== '' && email !== '' && role !== '' && phone !== '') {
      try {
        const id_user = uuidv4();
        const time = new Date();
        const userDB = doc(db, 'tardis/entities/users', id_user)
        setDoc(userDB, {
          name: name,
          email: email,
          role: role,
          phone: phone,
          uid: id_user,
          createAt: time
        }
        )
        navigate('/usuarios')
      } catch (error) {
        console.log('Error al registrar usuario')
      }
    } else {
      alert("Faltan datos para guardar registro")
      return

    }
  }

  const onChange = (e) => {
    if (e.target.name === 'username') {
      setname(e.target.value);
    }
    if (e.target.name === 'email') {
      setemail(e.target.value);
    }
    if (e.target.name === 'role') {
      setrole(e.target.value);
    }
    if (e.target.name === 'phone') {
      if (!Number(e.target.value)) {
        setphone(phone)
      } else {
        setphone(e.target.value);
      }
    }
  }


  return (
    <>
      <div className="container-fluid">
        <h3 className="text-dark mb-4">Nuevo usuario</h3>
        <div className="row mb-3">
          <div className="col-lg-4">
            <div className="card mb-3">
              <div className="card-body text-center shadow"><img className="rounded-circle mb-3 mt-4" alt='Avatar para nuevo usuario' src="\resources\img\dogs\image2.jpeg" width="160" height="160" />
                {/* <div className="mb-3"><button className="btn btn-primary btn-sm" type="button">Change Photo</button></div> */}
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="row">
              <div className="col">
                <div className="card shadow mb-3">
                  <div className="card-header py-3">
                    <p className="text-primary m-0 fw-bold">Datos</p>
                  </div>
                  <div className="card-body">
                    <form onSubmit={createUser}>
                      <div className="row">
                        <div className="col-md-12 col-lg-6">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="username">
                              <strong>Nombre</strong>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              id="username"
                              placeholder="Daniel Jaen"
                              name="username"
                              onChange={onChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-6">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="email">
                              <strong>E-mail</strong>
                            </label>
                            <input
                              className="form-control"
                              type="email"
                              id="email"
                              placeholder="djaen@pubsa.mx"
                              name="email"
                              onChange={onChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 col-lg-6">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="first_name">
                              <strong>Rol</strong>
                            </label>
                            <select
                              className="form-select"
                              aria-label="Default select role"
                              id="role"
                              name="role"
                              onChange={onChange}
                            >
                              <option disabled>Selecciona un rol</option>
                              <option value="Cliente">Cliente</option>
                              <option value="Traffick Manager">Traffick Manager</option>
                              <option value="Account Manager">Account Manager</option>
                              <option value="Team Leader">Team Leader</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-6">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="last_name">
                              <strong>Teléfono</strong>
                            </label>
                            <input
                              className="form-control"
                              type="tel"
                              id="phone"
                              placeholder="55 10 20 30 50"
                              name="phone"
                              onChange={onChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mb-3"><button className="btn btn-primary btn-sm" type="submit">Crear usuario</button></div>
                    </form>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default NewUser