import { Timestamp } from 'firebase/firestore'
import React, {useState, useEffect} from 'react'

const TableStatus = ({ row }) => {

  const [status, setstatus] = useState(false);
  const [statusElement, setstatusElement] = useState("En espera");
  const [statusIcon, setstatusIcon] = useState("fa-clock color-blue");

  useEffect(() => {

    if (row.lastUpdate) {
      setstatus(true);
    } else {
      setstatus(false);
    }

    if (status === true) {

      const time = new Date().getTime();
      const start = row.startDateMillis;
      const end = row.endDateMillis;

      switch (true) {
        case (time < start):
          setstatusElement("En espera");
          setstatusIcon("fa-clock blue");
          break;
        case (time >= start && time <= end):

          setstatusElement("Activa");
          setstatusIcon("fa-circle-check active");
          break;
        case (time > end):
          setstatusElement("Lapse");
          setstatusIcon("fa-circle-stop lapse");
          break;
        default:

          break;
      }
    }

  }, [row, status, statusElement, statusIcon])

  return (
    <div className='tooltiptardis'>
      <i
        className={`fa-solid icon-status ${statusIcon}`}>
      </i><span className='tooltiptext' >{statusElement}</span>
    </div>
  )
}

export default TableStatus