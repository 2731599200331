import React from 'react'
import {doc, db, deleteDoc} from '../../../../../firebaseConfig'

const TableActions = ({ row }) => {

  const deleteRecord = async (idRecord) => {
    console.log("idRecord: ", idRecord)
    try {
      await deleteDoc(doc(db, 'tardis/entities/g_account', idRecord))
    } catch (error) {
      console.log("error: " + error)
    }

  }

  return (
    <>
      <div className='dropdown no-arrow'>
        <a
          href="#"
          id={`dropdownElement-${row.idAccount}`}
          data-bs-toggle="dropdown"
          aria-expanded="false"
          className='dropdown-toggle text-black-50'
        >
          <i className="fa-solid fa-ellipsis-vertical icon-status px-3"></i>
        </a>
        <div className="dropdown-menu shadow dropdown-menu-end animated--grow-in" aria-labelledby={`dropdownElement-${row.idAccount}`}>
          <a className="dropdown-item disabled" href="#"><i className="fa-solid fa-pen-to-square fa-md pe-2"></i>Editar</a>
          <a
            className="dropdown-item"
            href="#"
            data-bs-toggle="modal"
            data-bs-target={`#Modal-${row.idAccount}`}
          >
            <i className="fa-solid fa-trash-can fa-md pe-2"></i>
            Eliminar
          </a>
        </div>
      </div>
      <div className="modal fade" id={`Modal-${row.idAccount}`} tabIndex="-1" aria-labelledby={`#ModalLabel-${row.idAccount}`} aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id={`#ModalLabel-${row.idAccount}`}>Eliminar registro</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              ¿Estas seguro de eliminar el registro de la cuenta {row.nameAccount}?
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
              <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => { deleteRecord(row.idRecord) }}>Eliminar</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TableActions