import React, { useEffect } from 'react'
import OverviewG from './OverviewG';


const Google = ({ settitle, user }) => {
  useEffect(() => {
    settitle('Google');
  }, [])


  return (
    <>
      <OverviewG settitle={settitle} user={user} />
    </>
  )
}

export default Google