import React from 'react'

const TableCampaignStatus = ({row}) => {
  return (
    <>
    {
      row.status == 'ENABLED' &&
      <div className='w-100 text-center icon-status active' ><i className="fa-solid fa-circle-play"></i></div>      
    }
    {
      row.status == 'PAUSED' &&
      <div className='w-100 text-center icon-status lapse' ><i className="fa-solid fa-circle-pause"></i></div>      
    }
    </>
    
  )
}

export default TableCampaignStatus