import React, { useState, useEffect } from 'react'
import moment from 'moment/min/moment-with-locales';
import Moment from 'react-moment'
import StatusElementG from './StatusElementG';
import { doc, db, deleteDoc } from '../../../../firebaseConfig'

Moment.globalMoment = moment;
Moment.globalLocale = 'es-mx';

const OverviewElement = ({ account }) => {

  const [percentTime, setpercentTime] = useState(0);
  const [percentBudget, setpercentBudget] = useState(0);
  const [suggestedBudget, setsuggestedBudget] = useState(0);

  const numberWithCommas = (x) => {
    x = parseFloat(x).toFixed(2);
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const percent = (a, b) => {
    return (a / b) * 100;
  }

  const deleteRecord = async (idRecord) => {
    console.log("idRecord: ", idRecord)
    try {
      await deleteDoc(doc(db, 'tardis/entities/g_account', idRecord))  
    } catch (error) {
      console.log("error: "+error)
    }
    
  }

  useEffect(() => {

    setpercentBudget(0)
    setpercentTime(0)

    const endDate = account.endDate.toMillis() - account.startDate.toMillis();
    const today = new Date().getTime() - account.startDate.toMillis();

    const days = ((endDate) ) / (1000 * 60 * 60 * 24);

    const sugBud = numberWithCommas((account.budget / (days)))

    //console.log("days: " + days + " budget" + account.budget + " sug:" + sugBud)
    setsuggestedBudget(sugBud);

    setTimeout(() => {

      setpercentBudget(numberWithCommas(percent(account.cost, account.budget)))
      setpercentTime(numberWithCommas(percent(today, endDate)))
    }, 800);


  }, [])

  return (
    <>
      <td>{account.idAccount}</td>
      <td className='tooltiptardis'>
        <span className='tooltiptext'>
          Impresiones: {account.impressions} <br />
          Clics: {account.clics} <br />
          Conversiones: {account.conversions}
        </span>
        {account.nameAccount}

      </td>
      <td className='text-center tooltiptardis'>
        <StatusElementG account={account} />
      </td>
      <td className='text-end tooltiptardis'>
        <span className="tooltiptext">
          <Moment
            date={account.startDate.toMillis()}
            add={{days:1}}
            durationFromNow
            format='D'
          />&nbsp;de&nbsp;
          <Moment diff={new Date(account.startDate.toMillis())} add={{days:1}} unit="days">{new Date(account.endDate.toMillis())}</Moment>
          &nbsp;días
        </span>        
        <Moment locale="es-mx" format='DD/MM/YYYY'>
          {account.startDate.toMillis()}
        </Moment>
        <br /> al &nbsp;
        <Moment format='DD/MM/YYYY'>
          {account.endDate.toMillis()}
        </Moment>
      </td>
      <td className='text-end tooltiptardis'>
        {
          account.cost ? (<>
            <span className='tooltiptext'>
              Saldo: $ {numberWithCommas(account.budget - account.cost)}
            </span>
            $ {numberWithCommas(account.cost)}
            <br />
            de $ {numberWithCommas(account.budget)}
          </>
          ) : (<span>Esperando datos {account.cost}</span>)
        }

      </td>
      <td className='text-end tooltiptardis'>
        {
          account.cost ? (
            <>
              <span className='tooltiptext'>
                Dev. hoy: $ {numberWithCommas(account.costToday)}
              </span>
              $ {suggestedBudget} <br />
              <span style={{ color: "grey" }}>P. P.: $ {numberWithCommas(account.budgetToday)}</span>
            </>
          ) : null
        }

      </td>
      <td className='td-table tooltiptardis'>
        <span className='tooltiptext'>Diferencia:&nbsp;{numberWithCommas(percentBudget - percentTime)}</span>
        <span className="progress mb-2">
          <span
            className="progress-bar bg-blue"
            aria-valuenow={`${percentTime}`}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: `${percentTime}%` }}
          >
            <i className="fa-regular fa-clock"><span className='fa-solid mx-1'> {percentTime}%</span>
            </i>
          </span>
        </span>
        <span className="progress">
          <span
            className="progress-bar bg-success"
            aria-valuenow={`${percentBudget}`}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: `${percentBudget}%` }}
          >
            <i className="fa-solid fa-dollar-sign"><span className='fa-solid mx-1'> {percentBudget}%</span>
            </i>
          </span>
        </span>
      </td>
      <td className='text-end tooltiptardis'>
        <span className='tooltiptext'>
          <Moment format=" hh:mm a, DD MMMM YYYY">
            {account.lastUpdate}
          </Moment>
        </span>
        {
          account.lastUpdate ? (<Moment fromNow>
            {account.lastUpdate}
          </Moment>) : <>En espera</>
        }

      </td>
      <td className='tooltiptardis'>
        <span className='tooltiptext'>
          Eliminar
        </span>
        <button
          className='btn'
          data-bs-toggle="modal"
          data-bs-target={`#Modal-${account.idAccount}`}>
          <i
            className="fa-solid fa-circle-xmark color-red"
          >
          </i>
        </button>
        
      </td>
      <td style={{width: 0}}>
      <div className="modal fade" id={`Modal-${account.idAccount}`} tabIndex="-1" aria-labelledby={`#ModalLabel-${account.idAccount}`} aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id={`#ModalLabel-${account.idAccount}`}>Eliminar registro</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              ¿Estas seguro de eliminar el registro de la cuenta {account.nameAccount}?
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
              <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => { deleteRecord(account.idRecord) }}>Eliminar</button>
            </div>
          </div>
        </div>
      </div>
      </td>
      
    </>
  )
}

export default OverviewElement