import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import TableCampaignStatus from './TableCampaignStatus';

const TableCampaign = ({ campaigns }) => {

  const [convMax, setconvMax] = useState(0);
  const [convMin, setconvMin] = useState(0);
  const [cpaMax, setcpaMax] = useState(0);
  const [cpaMin, setcpaMin] = useState(0);
  const [cpcMax, setcpcMax] = useState(0);
  const [cpcMin, setcpcMin] = useState(0);

  useEffect(() => {

    let convs = campaigns.map((e) => {return e.conversions});
    convs = convs.filter((e) => e>0);
    setconvMax(Math.max(...convs));
    setconvMin(Math.min(...convs))

    let cpas = campaigns.map((e) => {return e.cpa});
    cpas = cpas.filter((e)=>e>0);
    setcpaMax(Math.max(...cpas));
    setcpaMin(Math.min(...cpas));

    let cpcs = campaigns.map((e) => {return e.cpc});
    cpcs = cpcs.filter((e)=> e>0);
    setcpcMax(Math.max(...cpcs))
    setcpcMin(Math.min(...cpcs))

  }, [])


  const numberWithCommas = (x) => {
    x = parseFloat(x).toFixed(2);
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const numberWithCommasND = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const columns = [
    {
      name: 'Campaña',
      selector: row => row.campaignName,
      sortable: true,
      wrap: true,
      minWidth: '17rem',
      cell: (row) => (<span className='text-start w-100'>{row.campaignName}</span>)
    },
    {
      name: 'Estado',
      selector: row => row.status,
      sortable: true,
      center: true,
      cell: (row) => (<TableCampaignStatus row={row} />),
      width: '6rem',
    },
    {
      name: 'Impresiones',
      selector: row => row.impressions,
      sortable: true,
      right: true,
      cell: (row) => (<span className='w-100 text-end'>{numberWithCommasND(row.impressions)}</span>)
    },
    {
      name: 'Clics',
      selector: row => row.clicks,
      sortable: true,
      right: true,
      cell: (row) => (<span className='w-100 text-end'> {numberWithCommasND(row.clicks)}</span>)
    },
    {
      name: 'Cpc',
      selector: row => row.cpc,
      sortable: true,
      right: true,
      cell: (row) => <>$ {(numberWithCommas(row.cpc))}</>,
      conditionalCellStyles: [
        {
          when: row => row.cpc == cpcMax,
          style: {
            backgroundColor: 'rgba(242, 38, 19, 0.9)',
            color: 'white',
          }
        },
        {
          when: row => row.cpc == cpcMin,
          style: {
            backgroundColor: 'rgba(63, 195, 128, 0.9)',
            color: 'white',
          }
        }
      ]
    },
    {
      name: 'Ctr',
      selector: row => row.ctr,
      sortable: true,
      right: true,
      cell: (row) => <>{numberWithCommas(row.ctr)} %</>
    },
    {
      name: 'Conversiones',
      id: 'Conversiones',
      selector: row => row.conversions,
      sortable: true,
      right: true,
      cell: (row) => (<>{numberWithCommas(row.conversions)}</>),
      conditionalCellStyles: [
        {
          when: row => row.conversions == convMin || row.conversions == 0,
          style: {
            backgroundColor: 'rgba(242, 38, 19, 0.9)',
            color: 'white',
          }
        },
        {
          when: row => row.conversions == convMax,
          style: {
            backgroundColor: 'rgba(63, 195, 128, 0.9)',
            color: 'white',
          }
        }
      ]
    },
    {
      name: 'CPA',
      selector: row => row.cpa,
      sortable: true,
      right: true,
      cell: (row) => <>$ {(numberWithCommas(row.cpa))}</>,
      conditionalCellStyles: [
        {
          when: row => row.cpa == cpaMax || row.cpa == 0,
          style: {
            backgroundColor: 'rgba(242, 38, 19, 0.9)',
            color: 'white',
          }
        },
        {
          when: row => row.cpa == cpaMin,
          style: {
            backgroundColor: 'rgba(63, 195, 128, 0.9)',
            color: 'white',
          }
        }
      ]
    },
    {
      name: 'Valor de Conversion',
      selector: row => row.conversionValue,
      sortable: true,
      right: true,
      cell: (row) => (<>{numberWithCommas(row.conversionValue)}</>)
    },
    {
      name: 'Devengado',
      selector: row => row.cost,
      sortable: true,
      right: true,
      cell: (row) => (<>$ {numberWithCommas(row.cost)}</>)
    }
  ]

  const customStyles = {
    headRow: {
      style: {
        'font-size': '.9rem',
        'background-color': '#6610f2',
        'color': 'white',
      }
    },
    cells: {
      style: {
        'font-size': '.8rem',
      }
    }
  }


  return (
    <div className='ps-4 mb-2' >
      <DataTable
        striped
        responsive
        highlightOnHover='true'
        columns={columns}
        data={campaigns}
        defaultSortFieldId={'Conversiones'}
        customStyles={customStyles}
        defaultSortAsc={false}
      />
    </div>
  )
}

export default TableCampaign