import React, { useState, useEffect } from "react";
import { Test } from "./components/Test";
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom'
import Login from "./components/login/Login";
import Dashboard from "./components/dashboard/Dashboard";
import {auth, onAuthStateChanged} from './firebaseConfig'
import Register from "./components/register/Register";

function App() {

  const [user, setuser] = useState(null);

  useEffect(() => {
    const userTardis = auth.currentUser;
    if(userTardis){
      setuser(userTardis);
    }
  }, [user])
  

  return (
    <BrowserRouter>
      <Routes>
        <Route path='*' element={<Dashboard user={user} setuser={setuser}/>} />
        <Route path='/login' element={<Login setuser={setuser}/>} />
        <Route path='/register' element={ <Register setuser={setuser} /> } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
