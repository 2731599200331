import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import { db, collection, doc, getDocs, deleteDoc } from '../../../firebaseConfig';
import UserActions from './users/UserActions';

const Usuarios = ({ settitle }) => {

  const [tableTitle, settableTitle] = useState('Todos los usuarios');
  const [usersDB, setusersDB] = useState([])

  const getUsers = async () => {
    const result = [];

    const querySnapshot = await getDocs(collection(db, "tardis/entities/users"));
    querySnapshot.docs.map((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      return result.push({
        uid: doc.data().uid,
        name: doc.data().name,
        email: doc.data().email,
        role: doc.data().role,
        photo: doc.data().photo,
        phone: doc.data().phone,
        createAt: doc.data().createAt
      })
    });
    console.log('result: ', result)
    setusersDB(result);
    return result;
  }

  const deteleUser = async (id) => {
    console.log('Borrar id: ',id )
    await deleteDoc(doc(db, "tardis/entities/users", id));
    getUsers()
  }

  useEffect(() => {
    settitle('Usuarios');
    getUsers();
  }, [])


  return (
    <>
      <div className="card shadow">
        <div className="card-header py-3">
          <p className="text-primary m-0 fw-bold">{tableTitle}</p>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6 text-nowrap">
              <div id="dataTable_length" className="dataTables_length" aria-controls="dataTable">
                <div>
                  <h4>
                    <NavLink to='user'>
                      <button className='btn' type='button'> Agregar Usuario <i className="fa-solid fa-circle-plus mx-2"></i> </button>
                    </NavLink>

                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="text-md-end dataTables_filter" id="dataTable_filter"><label className="form-label"><input type="search" className="form-control form-control-sm" aria-controls="dataTable" placeholder="Buscar usuario" /></label></div>
            </div>
          </div>
          <div className="table-responsive-xxl table mt-2 d-flex flex-nowrap" role="grid" aria-describedby="dataTable_info">
            <table className="table my-0" id="dataTable">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th></th>
                  <th>Rol</th>
                  <th>Email</th>
                  <th>Teléfono</th>
                </tr>
              </thead>
              <tbody>
                {
                  usersDB.map((registro) => {
                    return (
                      <tr key={`reg-${registro.uid}`} className='flex-nowrap' >
                        <UserActions registro={registro} getUsers={getUsers} deteleUser={deteleUser} />
                      </tr>
                    )
                  })
                }
              </tbody>
              <tfoot>
                <tr>
                  <th>Nombre</th>
                  <th></th>
                  <th>Rol</th>
                  <th>Email</th>
                  <th>Teléfono</th>

                </tr>
              </tfoot>
            </table>
          </div>
          <div className="row">
            <div className="col-md-6 align-self-center">
              <p id="dataTable_info" className="dataTables_info" role="status" aria-live="polite">Mostrando {usersDB.length} usuarios</p>
            </div>
            <div className="col-md-6">
              <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                {/* <ul className="pagination">
                  <li className="page-item disabled"><a className="page-link" href="#" aria-label="Previous"><span aria-hidden="true">«</span></a></li>
                  <li className="page-item active"><a className="page-link" href="#">1</a></li>
                  <li className="page-item"><a className="page-link" href="#">2</a></li>
                  <li className="page-item"><a className="page-link" href="#">3</a></li>
                  <li className="page-item"><a className="page-link" href="#" aria-label="Next"><span aria-hidden="true">»</span></a></li>
                </ul> */}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Usuarios