import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { db, doc, setDoc } from '../../../../firebaseConfig';

const CreateElementG = ({ user, settitle }) => {

  const [element, setelement] = useState({
    idAccount: "",
    nameAccount: "",
    startDate: null,
    endDate: null,
    budget: 0,
    budgetNotification: 0,
    associatePM: "",
    comments: ""
  })

  const navigate = useNavigate();

  const [idAccountValidation, setidAccountValidation] = useState('')
  const [budgetNotificationValidation, setbudgetNotificationValidation] = useState('')
  
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Agregar seguimiento :D')
    if (element.idAccount === '' || element.nameAccount === '' || element.startDate === '' || element.endDate === '' || element.budget === '' || element.budget === 0) {
      return alert("Faltan datos para guardar registro");
    } else {
      try {
        const idRecord = uuidv4();
        const time = new Date();
        const record = { ...element, idRecord: idRecord, createdBy: user.uid, createdAt: time };
        const gAccountCollection = doc(db, 'tardis/entities/g_account', idRecord);
        setDoc(gAccountCollection, record, { merge: true });
        navigate('/google')
      } catch (error) {
        return alert("Error al intentar guardar el registro, intenta de nuevo más tarde")
      }
    }
  }
  const onChange = (e) => {
    //Input ID
    if (e.target.name === "idAccount") {
      setelement({ ...element, idAccount: e.target.value });
      switch (true) {
        case (element.idAccount.length == 11):
          setidAccountValidation('is-valid')
          break;
        case (element.idAccount.length > 11):
          setidAccountValidation('is-invalid')
          break;
        default:
          setidAccountValidation('')
          break;
      }
    }
    if (e.target.name === "nameAccount") {
      setelement({ ...element, nameAccount: e.target.value });
    }
    if (e.target.name === "startDate") {      
      let offset = (new Date().getTimezoneOffset()+60) * 60000
      const start = new Date(e.target.value).getTime() + offset;
      setelement({ ...element, startDate: new Date(start)})
    }
    if (e.target.name === "endDate") {
      let offset = ((new Date().getTimezoneOffset()+1440) * 60000) - 1000 
      const end = new Date(e.target.value).getTime() + offset;
      setelement({ ...element, endDate: new Date(end)})
    }
    if (e.target.name === "budget") {
      setelement({ ...element, budget: Number(parseFloat(e.target.value).toFixed(2)) })
      console.log("Tipo Budget: " + typeof element.budget)
    }
    if (e.target.name === "budgetNotification") {
      setelement({ ...element, budgetNotification: Number(parseFloat(e.target.value).toFixed(2)) })
      if (element.budgetNotification > element.budget) {
        setbudgetNotificationValidation('is-invalid')
      }
      if (element.budgetNotification < element.budget) {
        setbudgetNotificationValidation('')
      }
    }
    if (e.target.name === "comments") {
      setelement({ ...element, comments: (e.target.value) })
    }
  }

  useEffect(() => {
    settitle('Google > Agregar Cuenta')
  }, [])

  return (
    <>
      <div className="container-fluid">
        {/* <h3 className="text-dark mb-4">Agregar Elemento</h3> */}

        <div className="card shadow mb-3">
          <div className="card-header py-3">
            <p className="text-primary m-0 fw-bold">Seguimiento Google</p>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-sm-12 col-lg-6">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="username">
                      <strong>ID</strong>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${idAccountValidation}`}
                      id="idAccount"
                      placeholder="XXX-XXX-XXXX"
                      name="idAccount"
                      onChange={onChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-lg-6">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="email">
                      <strong>Nombre de la cuenta</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="nameAccount"
                      placeholder="Mi cuenta Google Ads"
                      name="nameAccount"
                      onChange={onChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-lg-6">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="first_name">
                      <strong>Fecha inicial</strong>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="startDate"
                      placeholder="Hoy"
                      name="startDate"
                      onChange={onChange}
                      max={element.endDate}
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-lg-6">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="last_name">
                      <strong>Fecha final</strong>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="endDate"
                      placeholder="Mañana"
                      name="endDate"
                      onChange={onChange}
                      min={element.startDate}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-lg-6">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="first_name">
                      <strong>Presupuesto</strong>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="budget"
                      placeholder="30,000"
                      name="budget"
                      onChange={onChange}
                      min={10}
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-lg-6">
                  <div className="mb-3 position-relative">
                    <label className="form-label" htmlFor="first_name">
                      <strong>Aviso Presupuesto</strong>
                    </label>
                    <input
                      type="number"
                      className={`form-control ${budgetNotificationValidation}`}
                      id="budgetNotification"
                      placeholder="20,000"
                      name="budgetNotification"
                      onChange={onChange}
                      min={10}
                      required
                    />
                    <div className="invalid-tooltip">
                      El aviso debe ser menor al presupuesto
                    </div>
                  </div>
                </div>

              </div>
              <div className="row">
                <div className="col">
                  <div className="form-label">
                    <strong>P. M. Asociado</strong>
                  </div>
                  <div className='input-group'>
                    <h4 className='btn' onClick={() => { alert('Hola!') }}><div className="badge bg-success">Cedeño <div className="vr" /><i className="fa-solid fa-user-minus mx-2"></i></div></h4>
                  </div>
                  <hr />
                  <div>
                    <h5 className='btn'><div className="badge bg-secondary">Nadia <div className="vr" /><i className="fa-solid fa-user-plus mx-1"></i></div></h5>
                    <h5 className='btn'><div className="badge bg-secondary">Javier <div className="vr" /><i className="fa-solid fa-user-plus mx-1"></i></div></h5>
                    <h5 className='btn'><div className="badge bg-secondary">David <div className="vr" /><i className="fa-solid fa-user-plus mx-1"></i></div></h5>
                    <h5 className='btn'><div className="badge bg-secondary">David <div className="vr" /><i className="fa-solid fa-user-plus mx-1"></i></div></h5>
                  </div>
                </div>

              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="signature">
                  <strong>Comentarios</strong><br />
                </label>
                <textarea
                  className="form-control"
                  id="comments"
                  rows="3"
                  name="comments"
                  onChange={onChange}
                />
              </div>
              <div className="mb-3">
                <button
                  className="btn btn-primary btn-sm mx-1"
                  type="submit"
                >
                  Guardar registro
                </button>
                <button
                  className='btn btn-danger btn-sm mx-1'
                  type='button'
                  onClick={() => { navigate('/google') }}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>

  )
}

export default CreateElementG