import React, { useState } from 'react'

const UserActions = ({ registro, deteleUser }) => {

  const [editStatus, seteditStatus] = useState(false);

  return (
    <>
      <td className='td-table'><img className="rounded-circle me-2" width="30" height="30" src={registro.photo ? (registro.photo) : ('/resources/img/avatars/avatar5.jpeg')} alt='avatar' />{registro.name}</td>
      <td className='td-table'>
        {editStatus ? (
          <>
            <button
              className="btn tooltiptardis"
              onClick={() => seteditStatus(false)}
            >
              <i
                className="fa-solid fa-circle-xmark color-yellow"
              >
              </i>
              <span className="tooltiptext">Cancelar</span>
            </button>
            <button className="btn tooltiptardis" onClick={() => seteditStatus(true)}><i className="fa-solid fa-floppy-disk color-green"></i> <span className="tooltiptext">Guardar</span></button>
          </>
        ) : (
          <>
            <button className="btn tooltiptardis" onClick={() => seteditStatus(true)}><i className="fa-regular fa-pen-to-square color-yellow"></i> <span className="tooltiptext">Editar</span></button>
            <button className="btn tooltiptardis" ><i className="fa-solid fa-paper-plane color-blue"></i> <span className="tooltiptext">Recuperar contraseña</span> </button>
            <button className="btn tooltiptardis" onClick={() => { deteleUser(registro.uid) }} ><i className="fa-solid fa-user-slash color-red"></i> <span className="tooltiptext">Eliminar</span></button>
          </>
        )}

      </td>
      <td className='td-table'>
        {editStatus ?
          (
            <select className='form-select' name="roleUser" id="roleUser">
              <option disabled>{registro.role}</option>
              <option value="Admin">Admin</option>
              <option value="Team Leader">Team Leader</option>
              <option value="Traffick Manager">Traffick Manager</option>
              <option value="Account Manager">Account Manager</option>
              <option value="Cliente">Cliente</option>
            </select>
          ) : (registro.role)
        }
      </td>
      <td className='td-table'>{registro.email}</td>
      <td className='td-table'>{registro.phone ? (
        <>
          <span>
            {registro.phone}
          </span>
          <button className="btn tooltiptardis px-2" onClick={() => window.open(`tel:${registro.phone}`)}><i className="fa-solid fa-phone color-blue"></i> <span className="tooltiptext">Llamar</span></button>
          <button className="btn tooltiptardis px-2" onClick={() => window.open(`https://wa.me/521${registro.phone}?text=Hola%20${registro.name}`, "_blank")}><i className="fa-brands fa-whatsapp color-wa"></i> <span className="tooltiptext">Enviar WhatsApp</span></button>
        </>
      ) : ('Sin teléfono')}</td>
    </>
  )
}

export default UserActions