import React, { useState } from 'react'
import { provider, auth, signInWithPopup, db, doc, setDoc } from '../../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Login = ({setuser}) => {

  const [email, setemail] = useState('');
  const [password, setpassword] = useState('');

  const navigate = useNavigate();

  const setUser = (user)=>{
    const usersCollection = doc(db,'tardis/entities/users',user.uid);
    setDoc(usersCollection,{
      uid: user.uid,
      name: user.displayName,
      email: user.email,
      photo: user.photoURL
    },{merge:true});

  }

  const singInGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        //const credential = provider.credentialFromResult(result);
        //const token = credential.accessToken;
        // The signed-in user info.
        const userTardis = result.user;
        console.log(userTardis)
        setuser(userTardis)
        navigate('/')
        // ...
//        <Navigate replace to='/dashboard' />
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = provider.credentialFromError(error);
        // ...
      });
  }

  return (
    <div className="bg-gradient-primary">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-9 col-lg-12 col-xl-10">
            <div className="card shadow-lg o-hidden border-0 my-5">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-6 d-none d-lg-flex">
                    <div className="flex-grow-1 bg-login-image" id='bg-login-image' ></div>
                  </div>
                  <div className="col-lg-6">
                    <div className="p-5">
                      <div className="text-center">
                        <h4 className="text-dark mb-4">¡Bienvenido!</h4>
                      </div>
                      <form className="user">
                        <div className="mb-3">
                          <input type="email" className="form-control form-control-user" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Ingresa tu correo..." name="email" disabled />
                        </div>
                        <div className="mb-3">
                          <input type="password" className="form-control form-control-user" id="exampleInputPassword" placeholder="Password" name="password" disabled />
                        </div>
                        <div className="mb-3">
                        </div>
                        <button className="btn btn-primary d-block btn-user w-100" type="submit" disabled>Acceder</button>
                        </form>
                        <hr />
                        <div id='btn-google' className='mx-auto'>
                          <button 
                          id='Sign_In' 
                          className="btn mb-2" 
                          role="button"
                          onClick={()=>{singInGoogle()}}
                          >
                            <img className='img-fluid' src="resources\img\btn_google_signin.png" alt="Botón inicia sesión con Google" /></button>
                        </div>
                        <hr />
                      
                      <div className="text-center"><Link to='/forgot-password' className="small" >Recuperar contraseña</Link></div>
                      <div className="text-center"><Link to='/register' className="small" >Crear una cuenta</Link></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login