import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Navbar from './Navbar';

const Header = ({ user, signOutGoogle, title }) => {
  const [photo, setphoto] = useState('\resources\img\avatars\avatar5.jpeg');
  const [name, setname] = useState('Usuario')

  useEffect(() => {
    if (user.photoURL) {
      setphoto(user.photoURL);
    }
    if (user.displayName) {
      setname(user.displayName)
    }
  }, [user])


  return (
    <>
      <nav className="navbar navbar-light navbar-expand bg-white shadow mb-4 topbar static-top">
        <div className="container-fluid">
          <button className="btn btn-link d-md-none rounded-circle me-3" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMenu" aria-controls="offcanvasMenu"><i className="fas fa-bars"></i></button>
          <h3 className='text-dark'>{title}</h3>
          <ul className="navbar-nav flex-nowrap ms-auto">
            <div className="d-none d-sm-block topbar-divider"></div>
            <li className="nav-item dropdown no-arrow">
              <div className="nav-item dropdown no-arrow">
                <Link to='#' aria-expanded="false" data-bs-toggle="dropdown" className="dropdown-toggle nav-link">
                  <span className="d-none d-lg-inline me-2 text-gray-600 small">{name}</span>
                  <img className="border rounded-circle img-profile" src={photo} /> :
                </Link>
                <div className="dropdown-menu shadow dropdown-menu-end animated--grow-in">
                  <Link to='#'className="dropdown-item" ><i className="fas fa-user fa-sm fa-fw me-2 text-gray-400"></i> Profile</Link>
                  <Link to='#'className="dropdown-item" ><i className="fas fa-cogs fa-sm fa-fw me-2 text-gray-400"></i> Settings</Link>
                  <Link to='#'className="dropdown-item" ><i className="fas fa-list fa-sm fa-fw me-2 text-gray-400"></i> Activity log</Link>
                  <div className="dropdown-divider"></div>
                  <Link to='#' className="dropdown-item" onClick={() => signOutGoogle()}><i className="fas fa-sign-out-alt fa-sm fa-fw me-2 text-gray-400"></i> Logout</Link>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
      <div className="offcanvas offcanvas-start " tabIndex='-1' id="offcanvasMenu" aria-labelledby="offcanvasMenu">
          <div className="offcanvas-header">
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body navbar navbar-dark align-items-start sidebar sidebar-dark accordion bg-gradient-primary p-0">
            <Navbar />
          </div>
        </div>
    </>
  )
}

export default Header