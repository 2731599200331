import React from 'react'
import DataTable from 'react-data-table-component';
import StatusElementG from './StatusElementG';
import TableStatus from './table/TableStatus';
import moment from 'moment/min/moment-with-locales';
import Moment from 'react-moment'
import TablePeriod from './table/TablePeriod';
import TableBars from './table/TableBars';
import TableCampaign from './table/tableCampaign/TableCampaign';
import TableActions from './table/TableActions';

Moment.globalMoment = moment;
Moment.globalLocale = 'es-mx';


const Table = ({ data, pending }) => {
  
  const ExpandedComponent = ({ data }) => <TableCampaign campaigns={data.campaigns} />;

  const columns = [
    {
      name: 'ID',
      selector: row => row.idAccount,
    },
    {
      name: 'Cuenta',
      id: 'cuenta',
      selector: row => row.nameAccount,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Estado',
      cell: row => <TableStatus row={row} />,
      center: true,
      compact: true,
      style: {
        padding: '0px'
      }
    },
    {
      name: "Periodo",
      selector: row => row.startDateMillis,
      sortable: true,
      cell: row => <TablePeriod row={row} />,
    },
    {
      name: "Presupuesto",
      selector: row => row.budget,
      sortable: true,
      right: true,
      format: (row) => (
        <div>$ {numberWithCommas(row.budget)}</div>
      )
    },
    {
      name: "Devengado",
      selector: row => row.cost,
      right: true,
      sortable: true,
      format: row => (
        <div>$ {numberWithCommas(row.cost)}</div>
      )
    },
    {
      name: "Saldo",
      selector: (row) => (row.budget - row.cost),
      right: true,
      sortable: true,
      cell: (row) => (<div>$ {numberWithCommas(row.budget - row.cost)}</div>),
    },
    {
      name: "Presupuesto Diario",
      selector: row => row.budgetToday,
      sortable: true,
      right: true,
      cell: (row) => (<><div className='text-end'>Sugerido: $ {numberWithCommas(((row.budget - row.cost) / ((row.endDateMillis - row.startDateMillis) / (1000 * 60 * 60 * 24))))}<br /> Real: $ {numberWithCommas(row.budgetToday)} </div></>),
      minWidth: '9rem',
      style: {
        with: '100%'
      }
    },
    {
      name: "Tiempo vs Inversión",
      cell: (row) => (<TableBars row={row} />),
      minWidth: '8rem',
    },
    {
      name: "Actualizado",
      selector: row => row.lastUpdate,
      cell: (row) => (<div className='text-end tooltiptardis'>
        <span className='tooltiptext'>
          <Moment format=" hh:mm a, DD MMMM YYYY">
            {row.lastUpdate}
          </Moment>
        </span>
        {
          row.lastUpdate ? (<Moment fromNow>
            {row.lastUpdate}
          </Moment>) : <>En espera</>
        }

      </div>
      )
    },
    {
      cell: (row) => (<TableActions row={row}/>),
      width: '3.5rem',
    }
  ];

  const customStyles = {
    headRow: {
      style:{
        'font-size': '1rem',
        'background-color': '#4e73df',
        'color': 'white'
      }
    },
    cells:{
      style:{
        'font-size': '.8rem',
      }
    }
  }

  const numberWithCommas = (x) => {
    x = parseFloat(x).toFixed(2);
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <div>
      <DataTable
        striped
        responsive
        highlightOnHover='true'
        columns={columns}
        data={data}
        progressPending={pending}
        defaultSortFieldId={'cuenta'}
        expandableRows
        expandOnRowClicked={true}
        expandableRowsComponent={ExpandedComponent} 
        customStyles={customStyles}
      />

    </div>
  )
}

export default Table