import React from 'react'
import moment from 'moment/min/moment-with-locales';
import Moment from 'react-moment'

Moment.globalMoment = moment;
Moment.globalLocale = 'es-mx';

const TablePeriod = ({row}) => {
  return (
    <div className='text-end tooltiptardis'>
        <span className="tooltiptext">
          <Moment
            date={row.startDateMillis}
            add={{days:1}}
            durationFromNow
            format='D'
          />&nbsp;de&nbsp;
          <Moment diff={new Date(row.startDateMillis)} add={{days:1}} unit="days">{new Date(row.endDateMillis)}</Moment>
          &nbsp;días
        </span>        
        <Moment locale="es-mx" format='DD/MM/YYYY'>
          {row.startDateMillis}
        </Moment>
        <br /> al &nbsp;
        <Moment format='DD/MM/YYYY'>
          {row.endDateMillis}
        </Moment>
      </div>
  )
}

export default TablePeriod